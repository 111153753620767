import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ClientDTO } from "utils/dtos/clientDTO";
import { convertQueryString } from "utils/workWithData";
import DomainHelper from "helpers/DomainHelper";

const baseUrl = DomainHelper.getAPIMain()
export interface ClientAnalyticsNodesDTO {
  id: string; step: number; name: string; visits: number;
}
interface ClientAnalyticsLinksDTO {
  source: string; target: string; value: number;
}
export interface ClientAnalyticsRowDTO {
  id: number; nodes: ClientAnalyticsNodesDTO[]; links: ClientAnalyticsLinksDTO[];
}
interface ClientAnalyticsResponse {
  rows: ClientAnalyticsRowDTO[];
  page: number;
  steps: number,
  isLastPage: boolean;
}
interface ClientAnalyticsStatsDTO {
  visitorsSeen: number;
  visitorsIdentified: number;
  visitorsICPMatched: number;
  visitorsScoreAvg: number;
  ICPScoreAvg: number;
  sessionDurationAvg: number;
  timeOnPageAvg: number;
  conversionRateAvg: number;
}
interface ClientState {
  data: ClientDTO | null
  dataById: any[]
  isLoading: boolean
  isSuccess: boolean
  errorMessage: string | unknown
  analyticsStats: {
    data: ClientAnalyticsStatsDTO | null;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
  };
  analytics: {
    data: ClientAnalyticsResponse | null
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
  }
  stepCount: number
  stats: {
    data: {
      planName: string | null;
      planState: string | null;
      rateLimitAvailable: number;
      rateLimitUsed: number;
      creditsRevealUsed: number;
      creditsRevealAvailable: number
    }
    isLoading: boolean;
    isError: boolean;
  };
}
const initialState: ClientState = {
  data: null,
  dataById: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: null,
  analytics: {
    data: null, isLoading: false, isSuccess: false, isError: false
  },
  analyticsStats: {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  stepCount: 1,
  stats: {
    data: {
      planName: null,
      planState: null,
      rateLimitAvailable: 0,
      rateLimitUsed: 0,
      creditsRevealUsed: 0,
      creditsRevealAvailable: 0
    },
    isLoading: false,
    isError: false,
  },
};
export const getClientStats = createAsyncThunk("client/getStats",async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}client/stats`, {withCredentials: true});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const getClientDTO = createAsyncThunk("client/get", async (clientId: string, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}client?client_id=${clientId}`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {return thunkAPI.rejectWithValue(err.response.data);}
});
export const updateWebhookURL = createAsyncThunk("client/updateWebhookURL", async (updateWebhookDto: {clientId: string; clayWebhookUrl: string | null}, thunkAPI) => {
  try {
    const {clientId, clayWebhookUrl} = updateWebhookDto;
    const response  = await axios.patch(`${baseUrl}client/${clientId}`, {clayWebhookUrl}, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    })
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {return thunkAPI.rejectWithValue(err.response.data)}
})
export const getClientAnalytics = createAsyncThunk("client/getAnalytics", async (queryParams: any, thunkAPI) => {
  try {
    const query = await convertQueryString({...queryParams});
    const response = await axios.get(`${baseUrl}client/analytics?${query}`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}})
export const getClientAnalyticsByNode = createAsyncThunk("client/analytics/path/analytics?", async (queryParams: any, thunkAPI) => {
  try {
    const query = await convertQueryString({...queryParams});
    const response = await axios.get(`${baseUrl}client/analytics/path/analytics?${query}`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {return thunkAPI.rejectWithValue(err.response.data);
}})
export const getClientAnalyticsStats = createAsyncThunk(
  "client/getAnalyticsStats",
  async (_, thunkAPI) => {
    const mockData = {
      visitorsSeen: 215569,
      visitorsIdentified: 109621,
      visitorsICPMatched: 2260,
      visitorsScoreAvg: 31,
      ICPScoreAvg: 87,
      sessionDurationAvg: 195,
      timeOnPageAvg: 27,
      conversionRateAvg: 3.17
    };

    try {
      const response = await axios.get(`${baseUrl}client/analytics/stats`, {
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      console.warn("Error fetching analytics stats. Returning mock data.");
      return thunkAPI.fulfillWithValue({ data: mockData });
    }
  }
);
export const clientSlice = createSlice({
  name: "crm", initialState, 
  reducers: {
    resetClientAnalyticsData: (state) => { state.analytics.data = null; },
    stepHandler: (state, action) => {state.stepCount = action.payload }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientDTO.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = null;
      state.data = action.payload.data;
    });
    builder.addCase(getClientDTO.rejected, (state, action: any) => {
      state.data = null;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getClientDTO.pending, (state) => { state.isLoading = true; });

    builder.addCase(updateWebhookURL.fulfilled, (state, action) => {if (state.data) { 
      state.data.clayWebhookUrl = action.payload?.data.clayWebhookUrl
      state.isLoading = false
    }})
    builder.addCase(updateWebhookURL.pending, (state) => {state.isLoading = true})
    builder.addCase(updateWebhookURL.rejected, (state) => {state.isLoading = false})

    builder.addCase(getClientAnalytics.fulfilled, (state, action) => {
      if (action.payload.data.page === 1) {
        state.analytics.data = action.payload.data;
      } else {
        if (!state.analytics.data || state.analytics.data?.rows?.length === 0) {
          state.analytics.data = action.payload.data;
        } else {
          const newData = action.payload.data as ClientAnalyticsResponse;
          let newRows = state.analytics.data.rows.map((row) => {
            const existingRows = newData.rows.filter((r) => r.id === row.id);
            if (existingRows.length > 0) {
              for (const existingRowItem of existingRows) {
                const removeExistingNodes = existingRowItem.nodes.filter((node) => !row.nodes.some((n) => n.id === node.id));
                const removeExistingLinks = existingRowItem.links.filter((link) => !row.links.some((l) => l.source === link.source && l.target === link.target));
                row.nodes = row.nodes.concat(removeExistingNodes);
                row.links = row.links.concat(removeExistingLinks);
                // row.nodes = row.nodes.filter((node) => node.step <= state.stepCount)
                // row.links = row.links.filter((node) => node. <= state.stepCount)
            }}
            return row
          });
          // ⭐ remove duplicated rows
          newRows = newRows.filter((row, index, self) => self.findIndex((r) => r.id === row.id) === index);
          state.analytics.data = { ...newData, rows: newRows }
        }
      }
      state.analytics.isSuccess = true;
      state.analytics.isLoading = false;
      state.analytics.isError = false;
    });
    builder.addCase(getClientAnalyticsByNode.fulfilled, (state, action) => {
      if (action.payload.data.page === 1) {
        state.analytics.data = action.payload.data;
      } else {
        if (!state.analytics.data || state.analytics.data?.rows?.length === 0) {
          state.analytics.data = action.payload.data;
        } else {
          const newData = action.payload.data as ClientAnalyticsResponse;
          let newRows = state.analytics.data.rows.map((row) => {
            const existingRows = newData.rows.filter((r) => r.id === row.id);
            if (existingRows.length > 0) {
              for (const existingRowItem of existingRows) {
                const removeExistingNodes = existingRowItem.nodes.filter((node) => !row.nodes.some((n) => n.id === node.id));
                const removeExistingLinks = existingRowItem.links.filter((link) => !row.links.some((l) => l.source === link.source && l.target === link.target));
                row.nodes = row.nodes.concat(removeExistingNodes);
                row.links = row.links.concat(removeExistingLinks);
                // row.nodes = row.nodes.filter((node) => node.step <= state.stepCount)
                // row.links = row.links.filter((node) => node. <= state.stepCount)
            }}
            return row
          });
          // ⭐ remove duplicated rows
          newRows = newRows.filter((row, index, self) => self.findIndex((r) => r.id === row.id) === index);
          state.analytics.data = { ...newData, rows: newRows }
        }
      }
      state.analytics.isSuccess = true;
      state.analytics.isLoading = false;
      state.analytics.isError = false;
    });
    builder.addCase(getClientAnalytics.rejected, (state, action: any) => {
      state.analytics.data = null;
      state.analytics.isLoading = false;
      state.analytics.isSuccess = false;
      state.analytics.isError = true;
    });
    builder.addCase(getClientAnalytics.pending, (state) => { state.analytics.isLoading = true; });
    
    builder.addCase(getClientStats.pending, (state) => {
      state.stats.isLoading = true;
      state.stats.isError = false;
    })
    builder.addCase(getClientStats.fulfilled, (state, action) => {
      state.stats = {
        data: {
          ...initialState.stats.data,
          ...action.payload.data
        },
        isLoading: false, 
        isError: false
      };
    })
    builder.addCase(getClientStats.rejected, (state) => {
      state.stats.isLoading = false;
      state.stats.isError = true;
    });
    builder.addCase(getClientAnalyticsStats.pending, (state) => {
      state.analyticsStats.isLoading = true;
      state.analyticsStats.isSuccess = false;
      state.analyticsStats.isError = false;
    })
    builder.addCase(getClientAnalyticsStats.fulfilled, (state, action) => {
      state.analyticsStats.isLoading = false;
      state.analyticsStats.isSuccess = true;
      state.analyticsStats.isError = false;
      state.analyticsStats.data = action.payload?.data || null;
    })
    builder.addCase(getClientAnalyticsStats.rejected, (state) => {
      state.analyticsStats.isLoading = false;
      state.analyticsStats.isSuccess = false;
      state.analyticsStats.isError = true;
      state.analyticsStats.data = null;
    });
  }
});
export const {
  resetClientAnalyticsData,
  stepHandler
} = clientSlice.actions;