import React, { useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import Icon from 'components/atoms/Icon/Icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  handleRevealContactByID,
  InterfacePixelLeadData,
} from 'store/slices/insights';
import Loading from 'components/atoms/Loading/Loading';
import { secondsToHMS, showDate } from 'utils/workWithData';
import PixelRow from './PulseRow';
import EntryFormatter from 'utils/EntryFormatter';
import Button from 'components/atoms/Button/Button';
import { toast } from 'react-toastify';
import LeadHelper from '../../../helpers/LeadHelper';
import { messages } from '../../../utils/message';
import constants from '../../../utils/constants';
import { useTableSelect } from '../../../utils/hooks/useTableSelect';
import SelectAllCheckbox from '../../molecules/SelectAllCheckbox/SelectAllCheckbox';
import Popup from '../../molecules/Popup/Popup';
import ExportModal from '../SearchMain/ExportModal';
import { EnrichmentContactRevealSourceENUM } from '../../../utils/enums/enrichment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LeadScore from 'components/atoms/LeadScore/LeadScore';
import { Checkbox } from '@mui/material';
import InputField from 'components/atoms/InputField/InputField';
import { setTriggerApplyFilters } from 'store/slices/ui.slice';
import { getClientICP } from 'store/slices/icp.slice';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'none';
    size?: string;
  }
}
const isMobile = window.innerWidth <= 810;
const PulseBody: React.FC<{
  searchPeople: string | number;
  setSearchPeople: React.Dispatch<React.SetStateAction<string | number>>;
  searchLinkedin: any;
  filterRevealed: { label: string; value: boolean } | null;
  filterJobTitle: any[];
  filterCompany: any[];
  filterIndustry: any[];
  filterLocationPerson: any[];
  filterLocationCompany: any[];
  filterGender: any[];
  filterSeniority: any[];
  filterDepartment: any[];
  filterCompanyHeadcount: any[];
  filterIncomeRange: any[];
  filterCompanyRevenue: any[];
  isAppliedFilter: boolean;
  setIsAppliedFilter: (newVal: boolean) => void;
  pageCurrent: number;
  setPageCurrent: React.Dispatch<React.SetStateAction<number>>;
  query: string;
}> = ({
  searchPeople,
  setSearchPeople,
  searchLinkedin,
  filterRevealed,
  filterLocationPerson,
  filterLocationCompany,
  filterIncomeRange,
  filterGender,
  filterSeniority,
  filterDepartment,
  filterJobTitle,
  filterIndustry,
  filterCompany,
  filterCompanyHeadcount,
  filterCompanyRevenue,
  pageCurrent,
  setPageCurrent,
  isAppliedFilter,
  setIsAppliedFilter,
  query,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: leads, isLoading, count } = useAppSelector((state) => state.insights.leads);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState<SortingState>(() => {
    const orderColumn = searchParams.get('order_column');
    const orderMethod = searchParams.get('order_method');
    return orderColumn ? [{ id: orderColumn, desc: orderMethod === 'desc' }] : [];
  });

  const {
    unselectedRows,
    setUnselectedRows,
    selectedRows,
    setSelectedRows,
    isModalExportOpen,
    setIsModalExportOpen,
    isAllListSelected,
    setIsAllListSelected,
    setAnchorExportSelectMenu,
    isExportSelectMenuOpen,
    anchorExportSelectMenu,
    resetExport,
    isAllRowsInPageSelected,
    handleSelectPeoplePage,
    handleSelectAllPeople,
    isSelectAllChecked,
  } = useTableSelect({
    leads: leads.map((el) => ({ ...el, leadId: el?.id })),
    count: leads?.length || 0,
  }); // Length will be replaced with count when pagination is done
  const [isSearching, setIsSearching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [searchPeopleInput, setSearchPeopleInput] = useState<string | number>('');
  useEffect(() => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set('current_page', String(pageCurrent));
      return newParams;
    });
  }, [pageCurrent]);
  useEffect(() => {
    if (!sorting?.[0]) {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.delete('order_column');
        newParams.delete('order_method');
        return newParams;
      });
      return;
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set('order_column', sorting?.[0]?.id);
      newParams.set('order_method', sorting?.[0]?.desc ? 'desc' : 'asc');
      return newParams;
    });
  }, [sorting, setSearchParams]);
  // Restore selected rows from URL params on load
  useEffect(() => {
    const selectedRowsFromParams = searchParams.get('selected_ids');
    if (selectedRowsFromParams) setSelectedRows(selectedRowsFromParams.split(','));
  }, [searchParams]);
  // Update URL when `selectedRows` changes
  useEffect(() => {
    if (selectedRows.length > 0) {
      searchParams.set('selected_ids', selectedRows.join(','));
    } else {
      searchParams.delete('selected_ids');
    }
    setSearchParams(searchParams);
  }, [selectedRows, searchParams, setSearchParams]);
  const { data: ICPData } = useAppSelector((state) => state.icp);
  useEffect(() => {
    dispatch(getClientICP());
  }, [dispatch]);
  const threshold =  ICPData?.threshold ?? 10;
  const [expanded, setExpanded] = useState<ExpandedState>({});
  // const data = useMemo(() => leads, [leads]);
  const columns = React.useMemo<ColumnDef<InterfacePixelLeadData>[]>(() =>
    isMobile
      ? [{  accessorKey: 'leadName',
            header: () => 'Lead',
            meta: { size: 'auto' },
            cell: ({ row, getValue }) => {
              const value: any = getValue();
              let dataSecondary: string | null = null;
              // if (row.original.email) dataSecondary = row.original.email;
              return <div className='flex items-center gap-3'><div className='text-left inline-grid'>
                <div className='text-base truncate text-white-500'>{value}</div>
                {dataSecondary && <div className='text-sm text-gray-400 shadow mt-1 truncate'>{dataSecondary}</div>}
              </div></div>
            }
        }]
      : [
            { accessorKey: 'leadScore',
              header: 'Score',
              meta: { size: '10%' },
              enableColumnFilter: false,
              cell: ({ row, getValue }) => {
                const value: any = getValue();
                const handleCheckboxChange = (rowId: string) => {
                  const tmpSetSelectedRows = isAllListSelected ? setUnselectedRows : setSelectedRows;
                  tmpSetSelectedRows((prev) => {
                    if (prev.includes(rowId)) return prev.filter((id) => id !== rowId);
                    else return [...prev, rowId];
                  });
                };
                return <div className='flex items-center'>
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(row.original.id);
                    }}
                    checked={ isAllListSelected ? !unselectedRows.includes(row.original.id) : selectedRows.includes(row.original.id) }
                    sx={{
                      color: 'var(--color-gray-600)',
                      '&.Mui-checked': { color: 'var(--color-primary-500)' },
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  />
                  <div className='flex justify-center'><LeadScore value={value}  threshold={threshold} loading={threshold === 0}/></div>
                </div>
            }},
            { accessorKey: 'leadName',
              header: () => 'Lead',
              meta: { size: 'auto' },
              cell: ({ row, getValue }) => {
                const value: any = getValue();
                let dataSecondary: string | null = null;
                // if (row.original.email) dataSecondary = row.original.email;
                return <div className='flex items-center gap-3'><div className='text-left inline-grid'>
                    <div className='text-base truncate text-white-500'>{value}</div>
                    {dataSecondary && <div className='text-sm text-gray-400 shadow mt-1 truncate'>{dataSecondary}</div>}
                </div></div>
            }},
            { accessorKey: 'orgName',
              header: () => 'Company',
              meta: { size: '15%' },
              enableSorting: true,
              cell: ({ row, getValue }) => {
                const value: any = getValue();
                if (value) return <div className='text-base truncate'>{value}</div>;
            }},
            { accessorKey: 'visits',
              header: 'Visits',
              meta: { filterVariant: 'none', size: '8%' },
              enableSorting: false,
              cell: ({ row, getValue }) => {
                const value = getValue() as string;
                if (value) return <div className='flex items-center justify-center text-sm'>{value}</div>
            }},
            { accessorKey: 'pageViews',
              header: () => 'Page Views',
              meta: { filterVariant: 'none', size: '9%' },
              enableSorting: false,
              cell: ({ row, getValue }) => {
                const value = getValue() as string;
                if (value) return <div className='flex items-center justify-center text-sm'>{value}</div>
            }},
            { accessorKey: 'duration',
              header: 'Duration',
              meta: { filterVariant: 'none', size: '8%' },
              enableSorting: false,
              cell: ({ row, getValue }) => {
                const value = getValue() as string;
                if (value) return <div className='flex items-center justify-center text-sm'>{secondsToHMS(value)}</div>
            }},
            { accessorKey: 'visitLast',
              header: () => 'Last seen',
              meta: { filterVariant: 'none', size: '15%' },
              cell: ({ row, getValue }) => {
                const value = getValue() as string;
                return <div className='relative flex items-center justify-center text-sm'>{showDate(value)}</div>
            }},
            { accessorKey: 'isContactRevealed',
              header: () => null,
              meta: { size: '13%' },
              cell: ({ row, getValue }) => {
                const isContactRevealed: any = getValue();
                if (!isContactRevealed) {
                  return <div className='flex justify-center'>
                    <Button iconName='credits' iconSize={15} size='xs'
                      onClick={(e: any) => {
                        e.stopPropagation();
                        dispatch(handleRevealContactByID({ leadId: row.original.id }))
                          .unwrap()
                          .then((data) => {
                            const CRData = data?.data;
                            if (LeadHelper.hasContactData(CRData))
                              toast.success(messages.ENRICHMENT_CONTACTREVEAL_SUCCESS, { toastId: 'contactRevealed' });
                            else
                              toast.error(messages.ENRICHMENT_CONTACTREVEAL_CONTACT_MISSING);
                          })
                          .catch((error) => { toast.error(error?.message); });
                      }}
                  >Reveal Contact</Button></div>
            }}}
    ],
    // eslint-disable-next-line
    [ threshold, isAllListSelected, selectedRows, setSelectedRows, setUnselectedRows, unselectedRows ]
  );
  const table = useReactTable({
    data: useMemo(() => leads, [leads]),
    columns,
    filterFns: {},
    state: { columnFilters, expanded, sorting },
    initialState: { pagination: { pageIndex: pageCurrent - 1, pageSize: 25 } },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    paginateExpandedRows: false,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true, //use pre-sorted row model instead of sorted row model
    onSortingChange: setSorting,
  });
  useEffect(() => {
    if (searchPeople === searchPeopleInput) return;
    setSearchPeopleInput(searchPeople as string);
  }, [searchPeople]);
  return (
    <>
      <div className='w-full flex p-2 borderBottom gap-3 justify-end items-center min-w-[800px]'>
        {/* <InputDebounce onChange={value => setSearchPeople(value) } value={searchPeople} placeholder='Search People' width='w-48' sizeInput='tiny' /> */}
        <div className='flex items-center justify-between w-full text-sm text-gray-400 px-1 gap-3 min-h-[40px]'>
          {/*{isSearching*/}
          {/*  ? <Loading height="auto" spinnerSize={24} />*/}
          {/*  : <><b className='text-white-500'>{EntryFormatter.formatNumber(leads.length)}</b> results found</>*/}
          {/*}*/}
          <form className='flex gap-2 items-center'
            onSubmit={(e) => {
              e.preventDefault();
              setSearchPeople(searchPeopleInput);
              setIsAppliedFilter(true);
              setPageCurrent(1);
              // fetchResults()
          }}>
            <InputField
              placeholder='Search People' size='tiny' value={searchPeopleInput}
              onChange={(e: any) => setSearchPeopleInput(e.target.value)}
            />
            <Button
              iconName='search' iconSize={16} size='xs'
              onClick={() => {
                setSearchPeople(searchPeopleInput);
                dispatch(setTriggerApplyFilters(true));
              }}
            >Search</Button>
          </form>
          <div className='flex items-center text-sm gap-5'>
            {isSearching || isLoading 
            ? <Loading height='auto' spinnerSize={38} />
            : <>
              {isAllListSelected || selectedRows?.length > 0 
              ? (<div className='flex items-center gap-5'>
                  <p className='text-gray-400'>
                    <b className='text-white-500'>
                      {isAllListSelected
                        ? Number(
                            (count > constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK ? constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK : count) - unselectedRows.length
                          ).toLocaleString()
                        : selectedRows.length}
                    </b>{' '}
                    selected
                  </p>
                  <Tooltip content='Export selected leads to CSV'>
                    <Button iconName='download' size='xs' className='float-right' onClick={() => setIsModalExportOpen(true)}>Export</Button>
                  </Tooltip>
                </div>
              ) : (
                <p className='text-gray-400 sm:block hidden'><b className='text-white-500 mr-1'>{EntryFormatter.formatNumber(count)}</b>{' '}results found</p>
              )}
              <Button onClick={() => navigate('/exports')} iconName='document' size='xs'>Export Lists</Button>
            </>}
          </div>
        </div>
      </div>
      {isLoading 
      ? <Loading />
      : <>
          <table className='min-w-[800px] '>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const isLeadOrVisit = ['leadName', 'visitLast'].includes(header.id);
                    const isAlignedLeft = ['leadName', 'orgName'].includes(header.id);
                    const canSort = header.column.getCanSort();
                    const sortedState = header.column.getIsSorted();
                    const columnName = header.column.columnDef.header;
                    return (
                      <th
                        key={header.id}
                        style={{
                          width: header.column.columnDef.meta?.size || 'auto',
                          textAlign: isAlignedLeft ? 'left' : 'center'
                        }}
                      >
                        {!isLeadOrVisit ? (
                          columnName === 'Score' ? (
                            <SelectAllCheckbox
                              count={count}
                              setAnchorExportSelectMenu={setAnchorExportSelectMenu}
                              isSelectAllChecked={isSelectAllChecked}
                              isAllListSelected={isAllListSelected}
                              resetExport={resetExport}
                              isAllRowsInPageSelected={isAllRowsInPageSelected}
                              handleSelectPeoplePage={handleSelectPeoplePage}
                              handleSelectAllPeople={handleSelectAllPeople}
                              isExportSelectMenuOpen={isExportSelectMenuOpen}
                              anchorExportSelectMenu={anchorExportSelectMenu}
                              header={header}
                            />
                          ) : (
                            <div className='flex flex-row items-center gap-2'>
                              {canSort ? (
                                <div
                                  className='group cursor-pointer select-none w-max flex'
                                  onClick={header.column.getToggleSortingHandler()}
                                  style={{ margin: isAlignedLeft ? '0' : '0 auto', }}
                                >
                                  <div className='flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition'>
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{asc: <Tooltip content='Sort Ascending'><Icon name='sort-up' size={16} /></Tooltip>,
                                      desc: <Tooltip content='Sort Descending'><Icon name='sort-down' size={16} /></Tooltip>,
                                    }[sortedState as string] ?? null}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className='group select-none w-max flex'
                                  onClick={header.column.getToggleSortingHandler()}
                                  style={{ margin: isAlignedLeft ? '0' : '0 auto' }}
                                >
                                  <div
                                    className={`flex items-center w-auto gap-2 ${
                                      ['visits','pageViews','duration'].includes(header.id)
                                        ? 'no-hover group-hover:!bg-transparent group-hover:!text-inherit'
                                        : 'group-hover:bg-paper-600 group-hover:text-white-500'
                                    }  px-2 py-1 rounded-xl transition`}
                                  >
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        ) : (
                          <div className='flex flex-row items-center gap-2'>
                            {canSort ? (
                              <div
                                className='group cursor-pointer select-none w-max flex'
                                onClick={header.column.getToggleSortingHandler()}
                                style={{ margin: isAlignedLeft ? '0' : '0 auto' }}
                              >
                                <div className='flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition'>
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  {{
                                    asc: <Tooltip content='Sort Ascending'><Icon name='sort-up' size={16} /></Tooltip>,
                                    desc: <Tooltip content='Sort Descending'><Icon name='sort-down' size={16} /></Tooltip>
                                  }[sortedState as string] ?? null}
                                </div>
                              </div>
                            ) : (
                              <div
                                className='group select-none w-max flex'
                                onClick={header.column.getToggleSortingHandler()}
                                style={{ margin: isAlignedLeft ? '0' : '0 auto' }}
                              >
                                <div
                                  className={`flex items-center w-auto gap-2 ${
                                    ['visitLast'].includes(header.id)
                                      ? 'no-hover group-hover:!bg-transparent group-hover:!text-inherit'
                                      : 'group-hover:bg-paper-600 group-hover:text-white-500'
                                  } px-2 py-1 rounded-xl transition`}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          </table>
          <div className='h-full overflow-y-auto min-w-[800px] overflow-x-hidden'>
            <table className={`main${table.getRowCount() === 0 || isSearching ? ' h-full' : ''}`}><tbody>
                {isSearching ? (
                  <tr className='noHover'><td><div className='flex flex-col gap-4 items-center justify-center h-full'>
                      <Loading height='auto' />
                      <h1 className='text-sm text-gray-400'>Searching...</h1>
                  </div></td></tr>
                ) : (
                  <>
                    {table.getRowCount() === 0 ? (
                      // ⭐ Empty State
                      <tr className='noHover'><td><div className='flex gap-3 items-center justify-center h-full'>
                        <Icon name='search' size={28} />
                        <h1 className='text-base'>No matching results were found</h1>
                      </div></td></tr>
                    ) : (
                      table.getRowModel().rows.map((row) => <PixelRow key={row.id} row={row} />)
                    )}
                  </>
                )}
            </tbody></table>
          </div>{' '}
      </>}
      <table className='min-w-[800px]'><tfoot><tr><td><div className='flex items-center justify-between'>
        <div className='flex items-center gap-1'>
          Showing{' '}
          <strong className='font-bold text-white-500'>{table.getRowCount() > 0
            ? <>
                {EntryFormatter.formatNumber((pageCurrent - 1) * 25 + 1)}
                -
                {EntryFormatter.formatNumber(pageCurrent * 25 > count ? count : pageCurrent * 25)}
            </>
            : 0
          }</strong>
          {'of '}
          <strong className='font-bold text-white-500'>{EntryFormatter.formatNumber(count || table.getRowCount())}</strong>
        </div>
        <div className='flex sm:flex-row gap-4 sm:gap-6 justify-end'><div className='pagination'>
            <div
              className={`${pageCurrent === 1 ? `disabled` : ``}`}
              onClick={() => setPageCurrent(1)}>
              <Icon name='angle-left-double' size={16} />
            </div>
            <div
              className={`${pageCurrent === 1 ? `disabled` : ``}`}
              onClick={() => setPageCurrent(pageCurrent - 1)}>
              <Icon name='angle-left' size={16} />
            </div>
            <div
              className={`${pageCurrent === Math.ceil(count / 25) ? `disabled` : ``}`}
              onClick={() => setPageCurrent(pageCurrent + 1)}>
              <Icon name='angle-right' size={16} />
            </div>
            <div
              className={`${pageCurrent === Math.ceil(count / 25) ? `disabled` : ``}`}
              onClick={() => setPageCurrent(Math.ceil(count / 25))}>
              <Icon name='angle-right-double' size={16} />
            </div>
        </div></div>
      </div></td></tr></tfoot></table>
      {isModalExportOpen && (
        <Popup onClose={() => setIsModalExportOpen(false)} type='modal'>
          <ExportModal
            source={EnrichmentContactRevealSourceENUM.PIXEL}
            totalRowsInList={count}
            isAllListSelected={isAllListSelected}
            setIsAllListSelected={setIsAllListSelected}
            unselectedRows={unselectedRows}
            selectedRows={selectedRows}
            setIsModalOpen={setIsModalExportOpen}
            setSelectedRows={setSelectedRows}
            query={query}
          />
        </Popup>
      )}
    </>
  );
};
export default PulseBody;
