/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "components/atoms/layout/Container/Container";
import "./../SearchMain/index.scss";
import { useAppDispatch } from "store/hooks";
import Icon from "components/atoms/Icon/Icon";
import { setCurrentPage } from "store/slices/ui.slice";
import { parseJSON } from "../../../utils/parseJson";
import { Outlet, useSearchParams } from "react-router-dom";
import { buildQueryString } from "../../../utils/buildQueryString";
import AnalyticsFilters from "./AnalyticsFilters";
import { getClientAnalytics, resetClientAnalyticsData } from "store/slices/client.slice";
import AnalyticsBody from "./AnalyticsBody";
import AnalyticClientStats from "./AnalyticClientStats";

const CONTENT_CLASS = `bg-gray-900 rounded-lg flex flex-col h-[76vh]`;
const AnalyticsModule: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [pageCurrent, setPageCurrent] = useState<number>(() => 1);
  // ⭐ Filters
  const [filterJobTitle, setFilterJobTitle] = useState<any[]>(() => parseJSON(params.job_title) || []);
  const [filterSeniority, setFilterSeniority] = useState<any[]>(() => parseJSON(params.seniority) || []);
  const [filterDepartment, setFilterDepartment] = useState<any[]>(() => parseJSON(params.department) || []);
  const [filterIndustry, setFilterIndustry] = useState<any[]>(() => parseJSON(params.industry) || []);
  const [filterCompanyHeadcount, setFilterCompanyHeadcount] = useState<any[]>(() => parseJSON(params.company_headcount) || []);
  const [filterLocationPerson, setFilterLocationPerson] = useState<any[]>(() => parseJSON(params.person_location) || []);
	const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (params.person_location) setFilterLocationPerson(parseJSON(params.person_location));
    if (params.job_title) setFilterJobTitle(parseJSON(params.job_title));
    if (params.seniority) setFilterSeniority(parseJSON(params.seniority));
    if (params.department) setFilterDepartment(parseJSON(params.department));
    if (params.industry) setFilterIndustry(parseJSON(params.industry));
    if (params.company_headcount) setFilterCompanyHeadcount(parseJSON(params.company_headcount));
  }, [searchParams]);
	const scrollToEnd = () => { if (scrollableRef.current) { scrollableRef.current.scrollTo({ left: scrollableRef.current.scrollWidth, behavior: "smooth" }); } };
  const personLocationFromUrl = searchParams.get("person_location")
  const jobTitleFromUrl = searchParams.get("job_title")
  const seniorityFromUrl = searchParams.get("seniority")
  const departmentFromUrl = searchParams.get("department")
  const industryFromUrl = searchParams.get("industry")
  const companyHeadcountFromUrl = searchParams.get("company_headcount")
  const queryParams = useCallback(() => { return {
    job_titles: buildQueryString(jobTitleFromUrl),
    company_headcounts: buildQueryString(companyHeadcountFromUrl),
    industries: buildQueryString(industryFromUrl),
    seniorities: buildQueryString(seniorityFromUrl),
    departments: buildQueryString(departmentFromUrl),
    locations_person: buildQueryString(personLocationFromUrl),
  }},[ jobTitleFromUrl, industryFromUrl, seniorityFromUrl, departmentFromUrl, personLocationFromUrl, companyHeadcountFromUrl ])
  const fetchResults = useCallback(() => {
    dispatch(getClientAnalytics( queryParams() ));
  }, [queryParams]);
  useEffect(() => { fetchResults(); }, [fetchResults]);
  useEffect(() => { dispatch( resetClientAnalyticsData() ) },[ jobTitleFromUrl, industryFromUrl, seniorityFromUrl, departmentFromUrl, personLocationFromUrl, companyHeadcountFromUrl ])
  useEffect(() => { window.analytics.page("Analytics"); dispatch(setCurrentPage('analytics')) }, [])
  useEffect(() => {
    setSearchParams((params) => { return new URLSearchParams(params); })
  }, [pageCurrent]);
 
  return <Container>
    <div className="flex sm:gap-6"><div className="items-center flex gap-3 text-white-500">
      <Icon name="analytics" size={30} />
      <h1 className="font-bold capitalize text-2xl">Analytics</h1>
    </div></div>
    <div className="flex sm:gap-6">
      <div className={`${CONTENT_CLASS} basis-1/5 gap-3 sm:flex hidden`}>
        <AnalyticsFilters isGroupedOptions
          pageCurrent={pageCurrent} setPageCurrent={setPageCurrent}
          setFilterJobTitle={setFilterJobTitle} filterJobTitle={filterJobTitle}
          isAppliedFilter={isAppliedFilter} setIsAppliedFilter={setIsAppliedFilter}
          filterIndustry={filterIndustry} setFilterIndustry={setFilterIndustry}
          filterLocationPerson={filterLocationPerson} setFilterLocationPerson={setFilterLocationPerson}
          filterDepartment={filterDepartment} setFilterDepartment={setFilterDepartment}
          filterCompanyHeadcount={filterCompanyHeadcount} setFilterCompanyHeadcount={setFilterCompanyHeadcount}
          filterSeniority={filterSeniority} setFilterSeniority={setFilterSeniority}
        />
      </div>

      <div className="w-full">
        {/* <AnalyticClientStats /> */}
        <div className={`SearchMainBody ${CONTENT_CLASS} overflow-y-auto w-full`}>
        
        {/* <div className="flex gap-4 items-center relative w-full text-sm text-gray-400 py-3 px-3 borderBottom h-[57px]">
          {analyticsLoading ? (
            <Loading height="auto" width="auto" spinnerSize={25} />
          ) : (
            !analyticsData?.isLastPage && (
            <Button size="xs" iconName="analytics" onClick={() => setPageCurrent(pageCurrent + 1)}>Load next step</Button>
            )
          )}  */}
          {/* {!analyticsLoading && <p className='text-gray-400 sm:block hidden'><b className='text-white-500 mr-1'>{analyticsData?.rows?.length || 0}</b>{' '}analytics found</p>} */}
        {/* </div>  */}
        <div ref={scrollableRef} className="h-full overflow-y-auto ">
          <AnalyticsBody scrollToEnd={scrollToEnd} setPageCurrent={setPageCurrent} pageCurrent={pageCurrent} />
        </div>

        </div>

      </div>
    </div>
    <Outlet/>
  </Container>
};
export default AnalyticsModule;