import constants from "./constants";
import Papa from "papaparse";
import { PlatformBrandName } from "./enums/platformBrandName";
import { LeadProfileDTO } from "./dtos/leadDTO";
import EntryFormatter from "./EntryFormatter";
import { CouponDTO } from "./dtos/couponDTO";
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getEmailBest = (data: LeadProfileDTO) => {
  return data?.emailWork?.email || data?.emailPersonal?.email || data?.emailAlt?.email || null;
}
export const getPhoneBest = (data: LeadProfileDTO) => {
  return data?.phone?.number || data?.phoneAlt?.number || null;
}
export const showDate = (dateRaw: any, displayTime: boolean = true, displayYear: boolean = false) => {
  if (!dateRaw) return "";
  var date = new Date(Date.parse(String(dateRaw)))
  const localTime = dayjs.utc(date).local();

  // var dateFormatted = date.toLocaleDateString("en-US", {
  //   timeZone: "UTC",
  //   month: "short", 
  //   day: "numeric", 
  //   hour: displayTime ? 'numeric' : undefined,
  //   minute: displayTime ? 'numeric' : undefined,
  //   hour12: true,
  //   year: !displayYear && new Date().getFullYear() === date.getFullYear() ? undefined : 'numeric'
  // })
  const dateFormatted = localTime.format(`MMM D,${!displayYear && new Date().getFullYear() === date.getFullYear() ? '' : ' YYYY,'}${displayTime ? ' h:mm A' : ''}`);
  return dateFormatted;
};
export const showEnrichmentOriginTitle = (enrichmentOriginType?: number) => {
  switch (enrichmentOriginType) {
    case 0:
      return "-";
    case 1:
      return "Identity Matrix";
    case 2:
      return "Traffic Intel";
    case 3:
      return "Bulk Search";
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return "Single Search";
  }
  return String(enrichmentOriginType);
};
export function secondsToHMS(seconds: number | string) {
  if (typeof seconds === 'string') seconds = parseInt(seconds);
  var hours = Math.floor(seconds / 3600);
  var minutes: any = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  var time = "";
  if (hours !== 0) {
    time = hours + ":";
  }
  if (minutes !== 0 || time !== "") {
    minutes = minutes < 10 && time !== "" ? "0" + minutes : String(minutes);
    time += minutes + ":";
  }
  if (time === "") {
    time = seconds + "s";
  } else {
    time += seconds < 10 ? "0" + seconds : String(seconds);
  }
  return time;
}
export const prepareForTable = (data: LeadProfileDTO | undefined, dataSecondary?: LeadProfileDTO | null) => {
  let stateCountry = '-';
  let location = '-';
  if (dataSecondary?.address) {
    stateCountry = dataSecondary.address.state || "-";
    if (dataSecondary.address.country && dataSecondary.address.state) stateCountry += `, ${dataSecondary.address.country}`;
    location = dataSecondary?.address?.city || "-";
    if (dataSecondary?.address?.city && dataSecondary?.address?.state) location += `, ${stateCountry}`;
  }
  else if (data?.address) {
    stateCountry = data.address.state || "-";
    if (data.address.country && data.address.state) stateCountry += `, ${data.address.country}`;
    location = data?.address?.city || "-";
    if (data?.address?.city && data?.address?.state) location += `, ${stateCountry}`;
  }
  return {
    // "First Name": data.nameFirst || "-",
    // "Last Name": data.nameLast || "-",
    LinkedIn: dataSecondary?.linkedInURL || data?.linkedInURL || "-",

    Company: dataSecondary?.organization?.name || data?.organization?.name || "-",
    Industry: dataSecondary?.organization?.industry || data?.organization?.industry || "-",
    "Job Title": EntryFormatter.formatJobTitle(dataSecondary?.linkedInTitle || data?.linkedInTitle),

    "Personal Email": dataSecondary?.emailPersonal || data?.emailPersonal  || "-",
    "Business Email": dataSecondary?.emailWork || data?.emailWork || "-",
    "Alt Email": dataSecondary?.emailAlt || data?.emailAlt || "-",

    Phone: dataSecondary?.phone || data?.phone || "-",
    "Alt Phone": dataSecondary?.phoneAlt || data?.phoneAlt || "-",

    Address: dataSecondary?.address?.street || data?.address?.street || "-",
    Location: location,
    // City: data?.address?.city || "-",
    // State: stateCountry || "-",
    // Zip: data?.address?.zipCode || "-",
    // Country: data?.address?.country || "-",
  };
};
export const convertQueryString = async (data: any) => {
  return new URLSearchParams(data).toString();
};
export const deleteFirstOfArray = (array: any) => {
  return array && Array.isArray(array) ? array.filter((elm, i) => i !== 0) : []; // eslint-disable-line no-param-reassign
};
const getLeadSingleEnrichmentCreditCost: (platform: number, userID?: string) => number = (platform, userID) => {
  let singleLeadEnrichmentCreditCost = constants.SINGLE_LEAD_ENRICHMENT_CREDIT_COST_DEFAULT;

  if (PlatformBrandName.QUALIGENCE === platform) {
    singleLeadEnrichmentCreditCost = constants.SINGLE_LEAD_ENRICHMENT_CREDIT_COST_QUALIGENCE;
  }

  // NOTE: This is a temporary solution to allow a specific user credit cost discount
  if (userID) {
    // 50%
    if (["f2861794-deb4-4008-9184-00ba05a90c34"].includes(userID)) {
      singleLeadEnrichmentCreditCost = constants.SINGLE_LEAD_ENRICHMENT_CREDIT_COST_DEFAULT * 0.5;
    }
    // 25%
    if (["65f73cc5-1e7c-4750-bdf3-21a8cd009096"].includes(userID)) {
      singleLeadEnrichmentCreditCost = constants.SINGLE_LEAD_ENRICHMENT_CREDIT_COST_DEFAULT * 0.75;
    }
  }

  return singleLeadEnrichmentCreditCost;
}
export const getCreditPrice: (platform: number, userID?: string) => number = (platform, userID) => {
  return getLeadSingleEnrichmentCreditCost(platform, userID);
};
export const getCreditSubscriptionPrice: (numCredits: number, userID: string, coupon?: CouponDTO | null) => {
  oneTime: number;
  monthlySubscription: number;
  annualSubscription: number;
} = (numCredits, userID, coupon) => {
  const prices = {
    oneTime: [1, 0.83, 0.66, 0.5], // One-time purchase prices per credit
    monthly: [0.9, 0.75, 0.6, 0.45], // Monthly subscription prices per credit
    annual: [0.77, 0.64, 0.51, 0.38], // Annual subscription prices per credit
  }
  const volume = [1000, 5000, 10000, 25000];

  // ⭐️ Discount factor
  let discountFactor = 1 // by default we don't apply any discount
  if (coupon) {
    const couponDiscountFactor = 1 - (coupon.discountPercent / 100);
    if (couponDiscountFactor > 0) discountFactor = couponDiscountFactor
  }
  // NOTE: This is a temporary solution to allow a specific user credit cost discount of 50%
  // if (["f2861794-deb4-4008-9184-00ba05a90c34"].includes(userID)) discountFactor = 0.50;
  // NOTE: This is a temporary solution to allow a specific user credit cost discount of 25%
  if (["65f73cc5-1e7c-4750-bdf3-21a8cd009096"].includes(userID)) discountFactor = 0.75;

  if (numCredits <= volume[0]) {
    return {
      oneTime: prices.oneTime[0] * numCredits * discountFactor,
      monthlySubscription: prices.monthly[0] * numCredits * discountFactor,
      annualSubscription: prices.annual[0] * numCredits * discountFactor,
    }
  } else if (numCredits <= volume[1]) {
    return {
      oneTime: prices.oneTime[1] * numCredits * discountFactor,
      monthlySubscription: prices.monthly[1] * numCredits * discountFactor,
      annualSubscription: prices.annual[1] * numCredits * discountFactor,
    };
  } else if (numCredits <= volume[2]) {
    return {
      oneTime: prices.oneTime[2] * numCredits * discountFactor,
      monthlySubscription: prices.monthly[2] * numCredits * discountFactor,
      annualSubscription: prices.annual[2] * numCredits * discountFactor,
    };
  } else if (numCredits <= volume[3]) {
    return {
      oneTime: prices.oneTime[3] * numCredits * discountFactor,
      monthlySubscription: prices.monthly[3] * numCredits * discountFactor,
      annualSubscription: prices.annual[3] * numCredits * discountFactor,
    };
  } else {
    return {
      oneTime: 0,
      monthlySubscription: 0,
      annualSubscription: 0,
    }
  }
}
export const csvReader = async (file: any) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray: any = [];
        const valuesArray: any = [];
        results.data.map((d: any) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        resolve(valuesArray);
      },
      error: function (error) {resolve(null);}
    });
  });
};
