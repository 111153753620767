import React from "react";
import styles from "./style.module.scss";
import InputField from "components/atoms/InputField/InputField";
import { useForm } from "react-hook-form";
import Button from "components/atoms/Button/Button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toast } from "react-toastify";
import { updateUserPassword } from "store/slices/user.slice";
import Heading from "components/atoms/layout/Heading/Heading";

const PasswordChange: React.FC = () => {
  const { register, handleSubmit, watch, formState, setValue } = useForm({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const { errors } = formState;

  const save = (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error("Please check confirmed password");
    } else {
      dispatch(
        updateUserPassword({
          newPassword: data.newPassword,
          currentPassword: data.currentPassword,
        })
      );
    }
  };

  return (
    <div className={`flex flex-col w-full gap-5`}>
      <Heading
        icon="lock"
        iconSize={33}
        title="Change Password"
      />
      <form onSubmit={handleSubmit(save)}>
        <div className={styles.container_fields}>
          <div className={styles.container_fields_input}>
            <label>Current Password</label>
            <InputField
              type="password"
              name="currentPassword"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Current Password is required",
              }}
              // placeholder="Current Password"
            />
          </div>
          <div className={styles.container_fields_input}>
            <label>New Password</label>
            <InputField
              type="password"
              name="newPassword"
              errors={errors}
              register={register}
              validationSchema={{
                required: "New Password is required",
                pattern: {
                  value:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
                  message:
                    "Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character",
                },
              }}
              // placeholder="New Password"
            />
          </div>
          <div className={styles.container_fields_input}>
            <label>Confirm new password</label>
            <InputField
              type="password"
              name="confirmPassword"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Confirm Password is required",
              }}
              // placeholder="Confirm Password"
            />
          </div>
          <div className={styles.container_fields_actions}>
            <Button>Update Password</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PasswordChange;
