import React from "react";
import Skeleton from "../Skeleton/Skeleton";

const PulseListSkeleton: React.FC = () => {
  return <div className="flex flex-col ">
    {/* Header Skeleton */}
    <div className="flex justify-between items-center p-2 border-b border-[var(--color-paper-600)]">
      <Skeleton width={200} height={32} />
      <Skeleton width={150} height={32} />
    </div>
    {/* Rows Skeleton */}
    {[...Array(10)].map((_, index) => (
      <div key={index} className="flex justify-between items-center px-4 border-b border-[var(--color-paper-600)] h-[58px]">
          <Skeleton width={85} height={30} />
          <Skeleton width={187} height={30} />
          <Skeleton width={120} height={30} />
          <div style={{gap: '50px'}} className="flex flex-row items-center mx-8">
            <Skeleton width={40} height={30} />
            <Skeleton width={40} height={30} />
            <Skeleton width={40} height={30} />
          </div>
          <Skeleton width={120} height={30} />
          <Skeleton width={100} height={30} />
      </div>
    ))}
    {/* Footer Skeleton */}
    <div className="flex justify-between items-center p-3">
      <Skeleton width={120} height={30} />
      <Skeleton width={140} height={30} />
    </div>
  </div>
}
export default PulseListSkeleton;