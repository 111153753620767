import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { convertQueryString } from "utils/workWithData";
import { PixelDTO } from "utils/dtos/pixelDTO";
import { ContactRevealedDTO } from "utils/dtos/contactRevealDTO";
import DomainHelper from "helpers/DomainHelper";
const baseUrl = DomainHelper.getAPIMain()
const urlEnrichment = DomainHelper.getAPIEnrichment()
export type IInsightsCompanySub = {
  id: string;
  name: string | number | null;
  // "avatarURL": string | null,
  // "email": string | null,
  // "leadScore"?: number,
  city?: string | null;
  state?: string | null;
  totalDuration?: number;
  pageViews?: number;
  visits?: number;
  visitors?: number;
  visitLast?: string;
  subRows?: InterfaceInsightsLead[];
};
type InterfacePulseOrganizationStats = {
  organizationId: string;
  pages: number;
  visitors: number;
  visits: number;
  duration: number;
};
type InterfacePixelLeadStats = {
  leadId: string;
  leadScore: number;
  pageViews: number;
  visits: number;
  duration: number;
};
export type InterfaceInsightsLead = {
  leadId: string;
  name: string;
  email: string;
  avatarURL: string;
  leadScore: number;
  pageViews: number;
  visits: number;
  duration: number;
  visitLast: string;
};
type InterfacePixelLeads = {
  isLoading: boolean;
  isLoadingCount: boolean;
  isSuccess: boolean;
  errorMessage: string | null;
  data: InterfacePixelLeadData[];
  count: number;
};
export type InterfacePixelLeadData = {
  id: string;
  leadName: string;
  leadScore: number | null;
  orgName: string;
  duration: number | null;
  visitLast: string;
  visits: number | null;
  pageViews: number | null;
  isContactRevealed: boolean;
};
interface IInsights {
  leads: InterfacePixelLeads;
  leadsStats: InterfacePixelLeadStats[];
  contactsRevealed: ContactRevealedDTO[];
  isCompaniesLoading: boolean;
  isCompanyPersonsLoading: Record<string, boolean>;
  isPixelPersonsLoading: Record<string, boolean>;
  companies: IInsightsCompanySub[];
  companiesStats: InterfacePulseOrganizationStats[];
  isSuccess: boolean;
  errorMessage: string | unknown;
  persons: Record<string, LeadProfileDTO[]>;
  isPersonSuccess: Record<string, boolean>;
  pixels: Record<string, PixelDTO[]>;
}
const initialState: IInsights = {
  leads: {
    isLoading: false,
    isSuccess: false,
    errorMessage: null,
    data: [],
    isLoadingCount: false,
    count: 0,
  },
  leadsStats: [],
  contactsRevealed: [] as ContactRevealedDTO[],
  isPixelPersonsLoading: {},
  errorMessage: "",
  isSuccess: false,
  isCompanyPersonsLoading: {},
  isCompaniesLoading: false,
  companies: [],
  companiesStats: [],
  persons: {},
  isPersonSuccess: {},
  pixels: {},
};
// TODO: remove this thunk and use getContactRevealedByID instead
export const handleRevealContactByID = createAsyncThunk("pixel/reveal_contact",async (data: { leadId: string }, thunkAPI) => {
  try {
    const response = await axios.post(`${urlEnrichment}lead/enrichment/contact_reveal/item`, { leadId: data.leadId, source: "pixel" }, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getAllPixelLeads = createAsyncThunk("data/getAllPixelLeads", async (queryParams: any, thunkAPI) => {
    try {
      const query = await convertQueryString(queryParams);
      const url = `${baseUrl}pixel/history/leads?${query}`;
      const response = await axios.get(url, { withCredentials: true });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const getTotalPixelLeads = createAsyncThunk("data/getTotalPixelLeads", async (queryParams: any, thunkAPI) => {
    try {
      const query = await convertQueryString({ ...queryParams, request_type: "count" });
      const url = `${baseUrl}pixel/history/leads?${query}`;
      const response = await axios.get(url, { withCredentials: true });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
}});
export const getAllCompanies = createAsyncThunk("data/getAllCompanies", async (queryParams: any, thunkAPI) => {
  try {
    const query = await convertQueryString(queryParams);
    const url = `${baseUrl}pixel/history/organizations?${query}`;
    const response = await axios.get(url, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getAllCompaniesPersons = createAsyncThunk("data/getAllCompaniesPersons", async (companyId: string, thunkAPI) => {
  try {
    const url = `${baseUrl}pixel/history/organization/leads?organization_id=${companyId}`;
    const response = await axios.get(url, { withCredentials: true });
    return thunkAPI.fulfillWithValue({ data: response.data.data.data, companyId });
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getOrganizationStat = createAsyncThunk("data/getOrganizationStat",async (params: { orgId: string }, thunkAPI) => {
  try {
    const url = `${baseUrl}pixel/history/organization/stats?organization_id=${params.orgId}`;
    const response = await axios.get(url, { withCredentials: true });
    return thunkAPI.fulfillWithValue({ data: response.data.data });
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getPixelsByPersons = createAsyncThunk(
  "data/getPixelsByPersons",
  async (leadId: string, thunkAPI) => {
    try {
      const url = `${baseUrl}pixel/history/lead/pixels?lead_id=${leadId}`;
      const response = await axios.get(url, { withCredentials: true });
      return thunkAPI.fulfillWithValue({
        data: response.data.data.data,
        leadId,
      } as any);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getLeadStats = createAsyncThunk(
  "pulse/getLeadStats",
  async (params: { leadId: string; orgId?: string }, thunkAPI) => {
    try {
      const url = `${baseUrl}pixel/history/lead/stats?lead_id=${
        params.leadId
      }&organization_id=${params.orgId || ""}`;
      const response = await axios.get(url, { withCredentials: true });
      return thunkAPI.fulfillWithValue({
        data: response.data.data,
        orgId: params.orgId,
      } as any);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getClientAnalyticsByNode = createAsyncThunk(
  "client/getAnalyticsByNode",
  async (queryParams: any, thunkAPI) => {
    try {
      const query = await convertQueryString({ ...queryParams });
      const response = await axios.get(
        `${baseUrl}client/analytics/path/leads?${query}`,
        { withCredentials: true }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


export const insightsSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetInsights: (state) => {
      state.companies = [];
      state.companiesStats = [];
      state.errorMessage = "";
      state.isCompaniesLoading = false;
      state.isCompanyPersonsLoading = {};
      state.isPersonSuccess = {};
      state.isPixelPersonsLoading = {};
      state.isSuccess = false;
      state.persons = {};
      state.pixels = {};
    },
    setLeadStats: (state, action: any) => {
      const elIndex = state.leads.data.findIndex((lead) => lead.id === action.payload.id);
      if (elIndex !== -1) {
        state.leads.data[elIndex] = {
          ...state.leads.data[elIndex],
          ...action.payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPixelLeads.pending, (state) => {
      state.leads.isLoading = true;
    });
    builder.addCase(getAllPixelLeads.fulfilled, (state, action) => {
      state.leads.isLoading = false;
      state.leads.isSuccess = true;
      state.leads.data = action.payload.data.data.map((el: any) => {
        const leadsData = state.leads.data?.find(
          (innerEl) => innerEl.id === el.id
        );
        if (leadsData) {
          el.duration = leadsData?.duration;
          el.visits = leadsData?.visits;
          el.score = leadsData?.leadScore;
          el.pageViews = leadsData?.pageViews;
        }
        return {
          ...el,
          ...(el.orgName ? { orgName: el.orgName } : { orgName: "" }),
        };
      });
      state.leads.data.forEach((el, i) => {
        if (!el.duration) el.duration = null;
        if (!el.visits) el.visits = null;
        if (!el.leadScore) el.leadScore = null;
        if (!el.pageViews) el.pageViews = null;
      });
    });

    builder.addCase(getAllPixelLeads.rejected, (state, action: any) => {
      state.leads.isLoading = false;
      state.leads.isSuccess = true;
      state.leads.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getClientAnalyticsByNode.pending, (state) => { state.leads.isLoading = true });
    builder.addCase(getClientAnalyticsByNode.fulfilled, (state, action) => {
      state.leads.isLoading = false;
      state.leads.isSuccess = true;
      state.leads.count = action.payload.data.data.length;
      state.leads.data = action.payload.data.data.map((el: any) => {
        const leadsData = state.leads.data?.find((innerEl) => innerEl.id === el.id);
        if (leadsData) {
          el.duration = leadsData?.duration;
          el.visits = leadsData?.visits;
          el.leadScore = leadsData?.leadScore;
          el.pageViews = leadsData?.pageViews;
        }
        return { ...el, ...(el.orgName ? { orgName: el.orgName } : { orgName: "" }) };
      });
      state.leads.data.forEach((el, i) => {
        if (!el.duration) el.duration = null;
        if (!el.visits) el.visits = null;
        if (!el.leadScore) el.leadScore = null;
        if (!el.pageViews) el.pageViews = null;
      });
    });

    builder.addCase(getClientAnalyticsByNode.rejected, (state, action: any) => {
      state.leads.isLoading = false;
      state.leads.isSuccess = true;
      state.leads.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder.addCase(getTotalPixelLeads.fulfilled, (state, action: any) => {
      state.leads.count = action.payload?.data?.total || 0;
      state.leads.isLoadingCount = false;
    });
    builder.addCase(getTotalPixelLeads.rejected, (state, action: any) => {
      state.leads.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder.addCase(getTotalPixelLeads.pending, (state, action) => {
      state.leads.isLoadingCount = true;
    });
   
    // ⭐ Get All Companies
    builder.addCase(getAllCompanies.pending, (state) => {
      state.isCompaniesLoading = true;
    });
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.isCompaniesLoading = false;
      state.isSuccess = true;
      state.companies = action.payload.data?.data || [];
    });
    builder.addCase(getAllCompanies.rejected, (state, action: any) => {
      state.isCompaniesLoading = false;
      state.isSuccess = true;
      state.errorMessage = action.payload?.message;
    });
    // ⭐ Reveal Contact
    builder.addCase(handleRevealContactByID.fulfilled, (state, action) => {
      const contactFound = state.contactsRevealed.find(
        (i) => i.id === action.meta.arg.leadId
      );
      if (!contactFound) return;
      contactFound.isLoading = false;
      contactFound.isSuccess = true;
      contactFound.errorMessage = null;
      contactFound.data = action.payload.data;
      const PixelLead = state.leads.data.find(
        (i) => i.id === action.meta.arg.leadId
      );
      if (PixelLead) PixelLead.isContactRevealed = true;
    });
    builder.addCase(handleRevealContactByID.pending, (state, action) => {
      const contactFound = state.contactsRevealed.find(
        (i) => i.id === action.meta.arg.leadId
      );
      if (contactFound) {
        contactFound.isSuccess = false;
        contactFound.isLoading = true;
        return;
      }
      state.contactsRevealed.push({
        id: action.meta.arg.leadId,
        data: null,
        isLoading: true,
        isSuccess: false,
        errorMessage: null,
      });
    });
    builder.addCase(handleRevealContactByID.rejected, (state, action: any) => {
      const contactFound = state.contactsRevealed.find(
        (i) => i.id === action.meta.arg.leadId
      );
      if (!contactFound) return;
      contactFound.isLoading = false;
      contactFound.isSuccess = false;
      contactFound.errorMessage =
        action.payload?.message || "Something went wrong";
      contactFound.data = null;
    });
    // ⭐ Get Organization Stats
    builder.addCase(getOrganizationStat.fulfilled, (state, action) => {
      const orgFound = state.companiesStats.find(
        (i) => i.organizationId === action.payload.data?.organizationId
      );
      if (orgFound) return;
      state.companiesStats.push({
        organizationId: action.payload.data.organizationId,
        pages: action.payload.data.pages || 0,
        visitors: action.payload.data.visitors || 0,
        visits: action.payload.data.visits || 0,
        duration: action.payload.data.duration || 0,
      });
    });
    builder.addCase(getLeadStats.fulfilled, (state, action) => {
      if (!action.payload?.data?.leadId) return;
      state.leadsStats.push({
        leadId: action.payload.data.leadId,
        leadScore: action.payload.data.leadScore || 0,
        pageViews: action.payload.data.pages || 0,
        visits: action.payload.data.visits || 0,
        duration: action.payload.data.duration || 0,
      });
    });
    builder.addCase(getAllCompaniesPersons.pending, (state, action) => {
      state.isCompanyPersonsLoading[action.meta.arg] = true;
    });
    builder.addCase(getAllCompaniesPersons.fulfilled, (state, action) => {
      state.isCompanyPersonsLoading[action.meta.arg] = false;
      const id = action.payload.companyId;
      state.isPersonSuccess[id] = true;
      state.persons[id] = action.payload.data;
    });
    builder.addCase(getAllCompaniesPersons.rejected, (state, action: any) => {
      state.isCompanyPersonsLoading[action.meta.arg] = false;
    });

    builder.addCase(getPixelsByPersons.pending, (state, action) => {
      state.isPixelPersonsLoading[action.meta.arg] = true;
    });
    builder.addCase(getPixelsByPersons.fulfilled, (state, action) => {
      state.isPixelPersonsLoading[action.meta.arg] = false;
      const id = action.payload.leadId;
      state.pixels[id] = action.payload.data;
    });
    builder.addCase(getPixelsByPersons.rejected, (state, action: any) => {
      state.isPixelPersonsLoading[action.meta.arg] = false;
    });
  },
});

export const { resetInsights, setLeadStats } = insightsSlice.actions;
