import React from "react";
import Icon from "components/atoms/Icon/Icon";
import { useAppSelector } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";

const EmailVerification: React.FC = () => {
  const { isLoading: userLoading } = useAppSelector((state) => state.user);
  return (
    <>
      {userLoading ? (
        <Loading />
      ) : (
        <div className="px-10 max-md:px-4 max-w-[1700px] mx-auto w-full max-2xl:max-w-[1320px] max-xl:max-w-[1024px] max-lg:max-w-[810px]  max-md:max-w-[630px]">
          {" "}
          <div
            className={`text-white-500 text-center flex-col gap-3 justify-center items-center px-4 py-12 sm:py-24 w-full  m-auto
               bg-gray-900 rounded-lg flex h-[76vh]
            `}
          >
            <Icon name="email" size={70} />
            <h1 className="text-xl mt-4">Please verify your email</h1>
            <p className="text-gray-400 text-base">
              In order to enjoy the full range of services we offer, please
              verify your email.
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default EmailVerification;
