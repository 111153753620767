import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "components/atoms/Menu/Menu";
import Icon from "components/atoms/Icon/Icon";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getClientStats } from "../../../store/slices/client.slice"
import ProgressBar from "components/atoms/ProgressBar/ProgressBar";
const Profile: React.FC<any> = ({ name, credits, logout, unlimitedCredits, dropDown = true }) => {
  const hasFetchedStats = useRef(false);
  const navigate = useNavigate();
  const [anchor, setAnchor]= useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const { data: user } = useAppSelector((state) => state.user);
  const { data: client } = useAppSelector((state) => state.client);
  const { data: stats } = useAppSelector((state) => state.client.stats);
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!open || hasFetchedStats.current) return
    dispatch(getClientStats());
    hasFetchedStats.current = true;
  }, [open]);
  return <>
    <div className="flex items-center gap-3 cursor-pointer" onClick={(e) => setAnchor(e.currentTarget)}>
      <div className="flex flex-col truncate">
        <div className="truncate">{name?.name} {name?.lastName}</div>
        <div className="flex items-center gap-2 mt-1 text-sm text-gray-400">
          <Icon name="credits" size={18} />
          {unlimitedCredits ? "Unlimited credits" : credits === 1 ? `${credits} credit` : `${credits || 0} credits`}
      </div></div>
      {dropDown && <Icon name={open ?'chevron-up':'chevron-down'} size={12} />}
    </div>
    {dropDown &&
      <Menu
        items={[
          {title: 'Account', description: 'Manage your account details', icon: 'user', onClick: ()=> navigate("/user/myAccount")},
          {title: 'Integrations', description: 'Connect third-party services', icon: 'layers', onClick: ()=> navigate("/integrations")},
          // {title: 'Billing & Payment', onClick: async ()=> {
          //     if (!user.gatewayCustomerId) {
          //       navigate("/user/billing")
          //       setTimeout(() => { toast.warn("Please add a payment method to your account before buying credits.") }, 900);
          //       return
          //     }
          //     const billingData = await dispatch( getBillingPortalUrl() )
          //     const url = billingData.payload.data;
          //     if (!url) {
          //       toast.error("Something went wrong. Please try again later.")
          //       return
          //     }
          //     window.open(url, "_blank")
          //   // navigate("/user/billing")
          // }},
          ...(user.isInvitedUser ? [] : [{title: 'Company Team', description: 'Manage your company team', icon: 'people',  onClick: () => navigate("/company")}]),
          {title: 'Logout', description: 'Sign out of your account', icon: 'logout', onClick: ()=> logout()},
        ]}
        isOpen={open} anchor={anchor} setAnchor={setAnchor} position={'right'}
      >
        {!user.isInvitedUser && client && stats.planName && <div className="pt-2 pb-3 px-3 flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <h1 className="text-white-500 text-base font-bold">{client.name}</h1>
            {(stats.planName || stats.planState) && <div className="flex gap-2">
              {stats.planName && <div className="badge pm xs">{stats.planName}</div>}
              {stats.planState && <div className="badge sd xs capitalize">{stats.planState}</div>}
            </div>}
          </div>
          <ProgressBar 
            used={stats.rateLimitUsed} 
            available={stats.rateLimitAvailable} 
            label="Traffic" iconName="score"
          />
          <ProgressBar
            used={stats.creditsRevealUsed}
            available={stats.creditsRevealAvailable} 
            label="Reveal Credits"  iconName="credits"
          />
        </div>}
      </Menu>
    }
  </>
};

export default Profile;
